import {createElement, renderElement} from '../utils';
import {activateModal, initializeModal, openModal} from '../modals';

const debounce = (f, t) => {
  return function (args) {
    let previousCall = this.lastCall;
    this.lastCall = Date.now();
    if (previousCall && ((this.lastCall - previousCall) <= t)) {
      clearTimeout(this.lastCallTimer);
    }
    this.lastCallTimer = setTimeout(() => f(args), t);
  }
};

const renderSearchResult = (block, obj, state) => {
  const searchInput = block.querySelector('.js-search-input input');
  const searchResultBlock = block.querySelector('.search-result');
  const searchHideBlock = block.querySelector('.hide-block');
  const searchAtr = searchInput.getAttribute('data-search');
  let searchingData = obj;
  let bookAnAppoinment = 'Записаться';
  let moreDetailed = 'Подробнее';

  if (document.querySelectorAll('.site-id-s2').length) {
    bookAnAppoinment = 'Book an appoinment';
    moreDetailed = 'More detailed';
  }

  if (searchAtr === 'doctor') {
    searchingData = searchingData.filter((el) => el.type === 'doctor');
  }

  if (searchAtr === 'direction') {
    searchingData = searchingData.filter((el) => el.type === 'direction');
  }

  const hideHideBlock = () => {
    if (searchHideBlock) {
      searchHideBlock.classList.add('hide-block--no-opacity');
    }
    setTimeout(() => {
      if (searchHideBlock) {
        searchHideBlock.classList.add('hide-block--hidden');
      }
      searchResultBlock.classList.remove('search-result--hidden');
    }, 300);
    setTimeout(() => {
      searchResultBlock.classList.remove('search-result--no-opacity');
    }, 310);
  };

  const showHideBlock = () => {
    searchResultBlock.classList.add('search-result--no-opacity');
    setTimeout(() => {
      if (searchHideBlock) {
        searchHideBlock.classList.remove('hide-block--hidden');
      }
      searchResultBlock.classList.add('search-result--hidden');
    }, 300);
    setTimeout(() => {
      if (searchHideBlock) {
        searchHideBlock.classList.remove('hide-block--no-opacity');
      }
    }, 310);
  };

  const filteringData = (data, value) => {
    let valueLower = value.toLowerCase();
    return data.filter((el) => (el.name.toLowerCase().startsWith(valueLower) || (Array.isArray(el.additionalSearch) && el.additionalSearch.some((val) => val.toLowerCase().includes(valueLower)))));
  };

  const createSearchElemMarcup = (data) =>
    data.map((el) => {
      if (searchAtr === 'direction') {
        return (
          `<li class="search-result__item">
          <div class="search-result__content">

            <div class="search-result__info">
              <a class="search-result__info-title" href="${el.href}" aria-label="Ссылка на страницу напраления">${el.name}</a>
            </div>
          </div>
        </li>`
        );
      } else {
        if (el.type === 'doctor') {
          if (window.innerWidth <= 767) {
            return (
              `<li class="search-result__item">
                <a class="btn btn--size-md btn--orange search-result__link" href="${el.href}" data-doctorid="${el.id}" aria-label="Записаться на приём">` + bookAnAppoinment + `</a>
                <div class="search-result__content">
                  <div class="search-result__img">
                    ${el.img ? `<img src="${el.img.src}" width="86" height="86" alt="${el.img.alt}"></img>` : ``}
                  </div>
                  <div class="search-result__info">
                    <a class="search-result__info-title" href="${el.href}">${el.name}</a>
                    <p>${el.description}</p>
                  </div>
                </div>
              </li>`
              );
          } else {
            return (
              `<li class="search-result__item">
                <a class="btn btn--size-md btn--orange search-result__link js-make-appointment-btn" href="${el.href}" data-doctorid="${el.id}" aria-label="Записаться на приём">` + bookAnAppoinment + `</a>
                <div class="search-result__content">
                  <div class="search-result__img">
                    ${el.img ? `<img src="${el.img.src}" width="86" height="86" alt="${el.img.alt}"></img>` : ``}
                  </div>
                  <div class="search-result__info">
                    <a class="search-result__info-title" href="${el.href}">${el.name}</a>
                    <p>${el.description}</p>
                  </div>
                </div>
              </li>`
              );
          }
        } else {
          return (
            `<li class="search-result__item">
            <span class="btn btn--size-md btn--orange search-result__link js-link" data-link="${btoa(el.href)}" aria-label="Подробнее">` + moreDetailed + `</span>
            <div class="search-result__content">
              <div class="search-result__info">
                <a class="search-result__info-title" href="${el.href}">${el.name}</a>
                <p>${el.description}</p>
              </div>
            </div>
          </li>`
          );
        }
      }
    }).join('');


  const hideResult = (arr) => {
    arr.forEach((el, i) => {
      el.classList.remove('search-result__item--active');
      el.classList.add('search-result__item--hidden');
      const delay = String(i / 10 * 5);
      const cleanDelay = delay.replace('.', '');
      el.style.transitionDelay = '0.' + cleanDelay + 's';
    });
  };

  const showResult = (arr) => {
    arr.forEach((el, i) => {
      el.classList.add('search-result__item--active');
      el.style.transitionDelay = '0.' + i * 1 + 's';
    });
  };

  const highlightLetters = (value) => {
    const searchResultTitles = document.querySelectorAll('.search-result__info-title');
    searchResultTitles.forEach((el) => {
      el.innerHTML = `<strong>${el.innerText.slice(0, value.length)}</strong>` + `${el.innerText.slice(value.length)}`;
    });
  };

  const createSearchElemTemplate = (data, value, flag) => {
    let newData = data;
    if(flag)
      newData = filteringData(data, value);
    const content = createSearchElemMarcup(newData);
    return (
      `<ul class="search-result__list">${content}</ul>`
    );
  };

  const renderResult = (data, flag = false) => {
    const value = searchInput.value;
    let cntSearch = 0;
    if(searchInput.classList.contains('search-gms'))
      cntSearch = 2;

    //if (searchInput.value.length > cntSearch) {
    if (searchInput.value.length) {
      let searchResult = block.querySelectorAll('.search-result__item');
      hideHideBlock();
      if (searchResult.length) {
        hideResult(searchResult);
        setTimeout(() => {
          searchResultBlock.innerHTML = '';
          renderElement(searchResultBlock, createElement(createSearchElemTemplate(data, value, flag)));
          searchResult = block.querySelectorAll('.search-result__item');
          if(flag)
            highlightLetters(value);
          showResult(searchResult);
          const makeAppointmentModal = document.querySelector('.js-make-appointment-modal');
          const makeAppointmentBtns = document.querySelectorAll('.js-make-appointment-btn');
          if (makeAppointmentModal && makeAppointmentBtns.length) {
            activateModal(makeAppointmentModal, makeAppointmentBtns, false, false);
          }
        }, 20);
      } else {
        searchResultBlock.innerHTML = '';
        renderElement(searchResultBlock, createElement(createSearchElemTemplate(data, value, flag)));
        searchResult = block.querySelectorAll('.search-result__item');
        const makeAppointmentModal = document.querySelector('.js-make-appointment-modal');
        const makeAppointmentBtns = document.querySelectorAll('.js-make-appointment-btn');
        if (makeAppointmentModal && makeAppointmentBtns.length) {
          activateModal(makeAppointmentModal, makeAppointmentBtns, false, false);
        }
        if(flag)
          highlightLetters(value);
        setTimeout(() => {
          showResult(searchResult);
        }, 30);
      }
    } else {

      showHideBlock();
      searchResultBlock.innerHTML = '';
    }
  };

  const onSearchInputInput = (evt) => {
    evt.preventDefault();

    if(evt.target.classList.contains('search-gms')) {
      if(evt.target.value.length) {
        let url = document.querySelector('.search-form-gms').getAttribute('action');
        fetch(`${url}?q=${evt.target.value}&search_ajax=y&spell=1`)
          .then((response) => response.json())
          .then((result) => renderResult(result, false));
      }
    }
    else
      renderResult(searchingData, true);
  };

  const onSearchInputInputDebounce = debounce(onSearchInputInput, 500);

  searchInput.addEventListener('input', onSearchInputInputDebounce);

  if (!state) {
    searchInput.removeEventListener('input', onSearchInputInputDebounce);
  }
};

export {renderSearchResult};
