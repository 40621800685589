import {openModal, closeModal} from './modals';
import {disableScrolling, enableScrolling} from './utils';

function setCookie(name, value, day = 7) {
  const date = new Date();
  date.setTime(date.getTime() + (day*24*60*60*1000));
  const options = {
    path: '/',
    expires: date.toUTCString(),
  };
  if (options.expires instanceof Date) {
    options.expires = options.expires.toUTCString();
  }
  let updatedCookie = encodeURIComponent(name) + '=' + encodeURIComponent(value);
  for (const optionKey in options) {
    if (Object.prototype.hasOwnProperty.call(options, optionKey)) {
      updatedCookie += '; ' + optionKey;
      const optionValue = options[optionKey];
      if (optionValue !== true) {
        updatedCookie += '=' + optionValue;
      }
    }
  }
  document.cookie = updatedCookie;
}

function getCookie(name) {
  const matches = document.cookie.match(new RegExp('(?:^|; )' + name.replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1') + '=([^;]*)'));
  return matches ? decodeURIComponent(matches[1]) : undefined;
}

const instaModal = document.querySelector('.js-make-insta-modal');
const ctaModal = document.querySelector('.js-make-cta-modal');

function showTwoModals(first, second) {
  openModal(first);

  first.querySelector('.modal__close-btn').addEventListener('click', () => {
    closeModal(first, null,() => {
      setTimeout(() => {
        openModal(second);
        second.querySelector('.modal__close-btn').addEventListener('click', () => {
          closeModal(second);
        });
      }, +second.dataset.delay * 1000);
    });
  });
}

const instaFirst = instaModal?.dataset.order && ctaModal?.dataset.order && (+ctaModal.dataset.order > +instaModal.dataset.order);

const initInstagramModal = () => {
  if (instaModal && ctaModal) {
    let modalsShown = false;

    if (ctaModal.querySelector('.js-make-appointment-btn')) {
      ctaModal.querySelector('.js-make-appointment-btn').addEventListener('click', (event) => {
        event.preventDefault();
        closeModal(ctaModal);
        setTimeout(disableScrolling, 350);
      });
    }

    window.addEventListener('scroll', () => {
      const content = document.querySelector('.js-show-modals-after');
      const SCROLL_MORE = 0;

      if ((window.scrollY > content.offsetTop + content.offsetHeight + SCROLL_MORE) && !modalsShown) {
        modalsShown = true;

        if (instaFirst) {
          showTwoModals(instaModal, ctaModal);
        } else {
          showTwoModals(ctaModal, instaModal);
        }
      }
    });
  } else {
    const instaCookie = getCookie('insta');
    if (!instaCookie) {
      if (window.location.href.indexOf('blog') !== -1) {
        setTimeout(() => {
          openModal(instaModal);
        }, 40000);

        instaModal.querySelector('.modal__close-btn').addEventListener('click', () => {
          setCookie('insta', 'yes', 7);
          closeModal(instaModal);
        });
      }
    }
  }
};

export {initInstagramModal, setCookie};
