const initMMenu = () => {
  const mMenuOptions = {
    counters: {
      add: true,
      count: true,
    },
    lazySubmenus: {
      load: true,
    },
    setSelected: true,
    iconbar: {
      use: '(min-width: 450px)',
    },
    navbars: [
      {
        content: ['prev', 'title', 'close'],
      },
    ],
  };

  if (document.querySelectorAll('.site-id-s2').length) {
    mMenuOptions.iconbar.bottom = [`
      <div class="sidebar-icon">
        <span>
          <svg class="svg-normal-mod" width="20" height="20">
            <use xlink:href="/local/templates/gms/img/sprite_auto.svg#icon-menu-doctor"></use>
          </svg>
          <svg class="svg-blind" width="20" height="20">
            <use xlink:href="/local/templates/gms/img/sprite_auto.svg#icon-menu-doctor-blind"></use>
          </svg>
        </span>
        <span>
          <svg class="svg-normal-mod" width="20" height="20">
            <use xlink:href="/local/templates/gms/img/sprite_auto.svg#icon-menu-products"></use>
          </svg>
          <svg class="svg-blind" width="20" height="20">
            <use xlink:href="/local/templates/gms/img/sprite_auto.svg#icon-menu-products-blind"></use>
          </svg>
        </span>
        <span>
          <svg class="svg-normal-mod" width="20" height="20">
            <use xlink:href="/local/templates/gms/img/sprite_auto.svg#icon-menu-way"></use>
          </svg>
          <svg class="svg-blind" width="20" height="20">
            <use xlink:href="/local/templates/gms/img/sprite_auto.svg#icon-menu-way-blind"></use>
          </svg>
        </span>
        <span>
          <svg class="svg-normal-mod" width="20" height="20">
            <use xlink:href="/local/templates/gms/img/sprite_auto.svg#icon-menu-locate"></use>
          </svg>
          <svg class="svg-blind" width="20" height="20">
            <use xlink:href="/local/templates/gms/img/sprite_auto.svg#icon-menu-locate-blind"></use>
          </svg>
        </span>
        <span>
          <svg class="svg-normal-mod" width="20" height="20">
            <use xlink:href="/local/templates/gms/img/sprite_auto.svg#icon-menu-heart"></use>
          </svg>
          <svg class="svg-blind" width="20" height="20">
            <use xlink:href="/local/templates/gms/img/sprite_auto.svg#icon-menu-heart-blind"></use>
          </svg>
        </span>
        <span>
          <svg class="svg-normal-mod" width="20" height="20">
            <use xlink:href="/local/templates/gms/img/sprite_auto.svg#icon-menu-wallet"></use>
          </svg>
          <svg class="svg-blind svg-blind--wallet" width="20" height="20">
            <use xlink:href="/local/templates/gms/img/sprite_auto.svg#icon-menu-wallet-blind"></use>
          </svg>
        </span>
        <span>
          <svg class="svg-normal-mod" width="20" height="20">
            <use xlink:href="/local/templates/gms/img/sprite_auto.svg#icon-menu-otzivi"></use>
          </svg>
          <svg class="svg-blind" width="20" height="20">
            <use xlink:href="/local/templates/gms/img/sprite_auto.svg#icon-menu-otzivi-blind"></use>
          </svg>
        </span>
        <span>
          <svg class="svg-normal-mod" width="20" height="20">
            <use xlink:href="/local/templates/gms/img/sprite_auto.svg#about"></use>
          </svg>
          <svg class="svg-blind" width="20" height="20">
            <use xlink:href="/local/templates/gms/img/sprite_auto.svg#about.svg"></use>
          </svg>
        </span>
        <span class="whatsapp-icon-menu">
          <svg class="svg-normal-mod" width="20" height="20">
            <use xlink:href="/local/templates/gms/img/sprite_auto.svg#whatsapp"></use>
          </svg>
          <svg class="svg-blind" width="20" height="20">
            <use xlink:href="/local/templates/gms/img/sprite_auto.svg#whatsapp"></use>
          </svg>
        </span>
      </div>
    `];
  } else {
    mMenuOptions.iconbar.bottom = [`
      <div class="sidebar-icon">
        <span>
          <svg class="svg-normal-mod" width="20" height="20">
            <use xlink:href="/local/templates/gms/img/sprite_auto.svg#icon-menu-doctor"></use>
          </svg>
          <svg class="svg-blind" width="20" height="20">
            <use xlink:href="/local/templates/gms/img/sprite_auto.svg#icon-menu-doctor-blind"></use>
          </svg>
        </span>
        <span>
          <svg class="svg-normal-mod" width="20" height="20">
            <use xlink:href="/local/templates/gms/img/sprite_auto.svg#icon-menu-products"></use>
          </svg>
          <svg class="svg-blind" width="20" height="20">
            <use xlink:href="/local/templates/gms/img/sprite_auto.svg#icon-menu-products-blind"></use>
          </svg>
        </span>
        <span>
          <svg class="svg-normal-mod" width="20" height="20">
            <use xlink:href="/local/templates/gms/img/sprite_auto.svg#icon-menu-heart"></use>
          </svg>
          <svg class="svg-blind" width="20" height="20">
            <use xlink:href="/local/templates/gms/img/sprite_auto.svg#icon-menu-heart-blind"></use>
          </svg>
        </span>
        <span>
          <svg class="svg-normal-mod" width="20" height="20">
            <use xlink:href="/local/templates/gms/img/sprite_auto.svg#icon-menu-way"></use>
          </svg>
          <svg class="svg-blind" width="20" height="20">
            <use xlink:href="/local/templates/gms/img/sprite_auto.svg#icon-menu-way-blind"></use>
          </svg>
        </span>
        <span>
          <svg class="svg-normal-mod" width="20" height="20">
            <use xlink:href="/local/templates/gms/img/sprite_auto.svg#icon-menu-disease"></use>
          </svg>
          <svg class="svg-blind" width="20" height="20">
            <use xlink:href="/local/templates/gms/img/sprite_auto.svg#icon-menu-disease-blind"></use>
          </svg>
        </span>
        <span>
          <svg class="svg-normal-mod" width="20" height="20">
            <use xlink:href="/local/templates/gms/img/sprite_auto.svg#icon-menu-ambulance"></use>
          </svg>
          <svg class="svg-blind" width="20" height="20">
            <use xlink:href="/local/templates/gms/img/sprite_auto.svg#icon-menu-ambulance-blind"></use>
          </svg>
        </span>
        <span>
          <svg class="svg-normal-mod" width="20" height="20">
            <use xlink:href="/local/templates/gms/img/sprite_auto.svg#icon-menu-home-doctor"></use>
          </svg>
          <svg class="svg-blind" width="20" height="20">
            <use xlink:href="/local/templates/gms/img/sprite_auto.svg#icon-menu-home-doctor-blind"></use>
          </svg>
        </span>
        <span>
          <svg class="svg-normal-mod" width="20" height="20">
            <use xlink:href="/local/templates/gms/img/sprite_auto.svg#icon-menu-wallet"></use>
          </svg>
          <svg class="svg-blind svg-blind--wallet" width="20" height="20">
            <use xlink:href="/local/templates/gms/img/sprite_auto.svg#icon-menu-wallet-blind"></use>
          </svg>
        </span>
        <span>
          <svg class="svg-normal-mod" width="20" height="21">
            <use xlink:href="/local/templates/gms/img/sprite_auto.svg#icon-menu-action"></use>
          </svg>
          <svg class="svg-blind" width="20" height="21">
            <use xlink:href="/local/templates/gms/img/sprite_auto.svg#icon-menu-action-blind"></use>
          </svg>
        </span>
        <span>
          <svg class="svg-normal-mod" width="20" height="20">
            <use xlink:href="/local/templates/gms/img/sprite_auto.svg#icon-menu-otzivi"></use>
          </svg>
          <svg class="svg-blind" width="20" height="20">
            <use xlink:href="/local/templates/gms/img/sprite_auto.svg#icon-menu-otzivi-blind"></use>
          </svg>
        </span>
        <span>
          <svg class="svg-normal-mod" width="20" height="20">
            <use xlink:href="/local/templates/gms/img/sprite_auto.svg#icon-menu-blog"></use>
          </svg>
          <svg class="svg-blind" width="20" height="20">
            <use xlink:href="/local/templates/gms/img/sprite_auto.svg#icon-menu-blog-blind"></use>
          </svg>
        </span>
        <span>
          <svg class="svg-normal-mod" width="20" height="20">
            <use xlink:href="/local/templates/gms/img/sprite_auto.svg#icon-menu-locate"></use>
          </svg>
          <svg class="svg-blind" width="20" height="20">
            <use xlink:href="/local/templates/gms/img/sprite_auto.svg#icon-menu-locate-blind"></use>
          </svg>
        </span>
        <span>
          <svg class="svg-normal-mod" width="20" height="20">
            <use xlink:href="/local/templates/gms/img/sprite_auto.svg#icon-menu-heart"></use>
          </svg>
          <svg class="svg-blind" width="20" height="20">
            <use xlink:href="/local/templates/gms/img/sprite_auto.svg#icon-menu-heart-blind"></use>
          </svg>
        </span>
        <span>
          <svg class="svg-normal-mod" width="20" height="20">
            <use xlink:href="/local/templates/gms/img/sprite_auto.svg#icon-menu-info"></use>
          </svg>
          <svg class="svg-blind" width="20" height="20">
            <use xlink:href="/local/templates/gms/img/sprite_auto.svg#icon-menu-info-blind"></use>
          </svg>
        </span>
        <span>
          <svg class="svg-normal-mod" width="20" height="20">
            <use xlink:href="/local/templates/gms/img/sprite_auto.svg#about"></use>
          </svg>
          <svg class="svg-blind" width="20" height="20">
            <use xlink:href="/local/templates/gms/img/sprite_auto.svg#about"></use>
          </svg>
        </span>
        <span>
          <svg class="svg-normal-mod" width="20" height="20">
            <use xlink:href="/local/templates/gms/img/sprite_auto.svg#icon-menu-blog"></use>
          </svg>
          <svg class="svg-blind" width="20" height="20">
            <use xlink:href="/local/templates/gms/img/sprite_auto.svg#icon-menu-blog-blind"></use>
          </svg>
        </span>
        <span>
          <svg class="svg-normal-mod" width="20" height="20">
            <use xlink:href="/local/templates/gms/img/sprite_auto.svg#icon-menu-login"></use>
          </svg>
          <svg class="svg-blind" width="20" height="20">
            <use xlink:href="/local/templates/gms/img/sprite_auto.svg#icon-menu-login-blind"></use>
          </svg>
        </span>
        <span class="whatsapp-icon-menu">
          <svg class="svg-normal-mod" width="20" height="20">
            <use xlink:href="/local/templates/gms/img/sprite_auto.svg#whatsapp"></use>
          </svg>
          <svg class="svg-blind" width="20" height="20">
            <use xlink:href="/local/templates/gms/img/sprite_auto.svg#whatsapp"></use>
          </svg>
        </span>
        <span class="telegram-icon-menu">
          <svg class="svg-normal-mod" width="22" height="22">
            <use xlink:href="/local/templates/gms/img/sprite_auto.svg#telegram"></use>
          </svg>
          <svg class="svg-blind" width="22" height="22">
            <use xlink:href="/local/templates/gms/img/sprite_auto.svg#telegram"></use>
          </svg>
        </span>
      </div>
    `];
  }

  document.addEventListener('DOMContentLoaded', () => {
    fetch('/local/ajax/menu').then((r) => {
      if (!r.ok) {
        throw Error(r.statusText);
      }
      return r;
    }).then((r) => r.json()).then((data) => {
      const withSumbenuItems = document.querySelectorAll('[data-submenuid]');
      withSumbenuItems.forEach((item) => {
        const id = +item.dataset.submenuid;
        const newSubmenu = data.find((item) => item.id === id);
        let innerHtml = '';

        newSubmenu.subMenu.forEach((menuItem) => {
          innerHtml += `<li><a href="${menuItem.link}">${menuItem.text}</a></li>`;
        });
        item.querySelector('ul').innerHTML = innerHtml;
      });
      
      window.menu = new Mmenu(document.getElementById('menu'), mMenuOptions);
    }).catch((error) => {
      window.menu = new Mmenu(document.getElementById('menu'), mMenuOptions);
    });
  });

  document.addEventListener('click', (e) => {
    const anchor = e.target.closest('a[href^="#/"]');
    if (anchor) {
      e.preventDefault();
    }
  });
};

export default initMMenu;
